import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { db, auth } from "../../firebase";
import { doc, collection, getDoc, serverTimestamp } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import GoToTop from "../../GoToTop";
import { format } from "date-fns";
const initialState = {
  title: "",
  pDesc: "",
};
function ProjectDetail() {
  const [data, setData] = useState(initialState);

  const {
    title,
    pDesc,
    work_type,
    work_level,
    work_duration,
    fileURL,
    maxCost,
    minCost,
    user,
    skills,
    posted,
    location,
  } = data;
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    id && getSingleProject();
  }, [id]);

  const getSingleProject = async () => {
    const docRef = doc(db, "Projects", id);
    const snapshot = await getDoc(docRef, "Projects");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const formattedDate = posted
    ? format(posted.toDate(), "MMMM d, yyyy")
    : "Date not available";

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          // Try fetching from the Freelancers collection
          const freelancerDocRef = db.collection("Projects").doc(userId);
          const freelancerDoc = await freelancerDocRef.get();

          if (freelancerDoc.exists) {
            const userData = freelancerDoc.data();
            setUserData(userData);
          } else {
            // If not found in Freelancers, try fetching from the Clients collection
            const clientDocRef = db.collection("Clients").doc(userId);
            const clientDoc = await clientDocRef.get();

            if (clientDoc.exists) {
              const userData = clientDoc.data();
              setUserData(userData);
            } else {
              console.log("User document does not exist in either collection");
            }
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User is not authenticated");
      }

      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData();
      } else {
        setLoading(false);
        console.log("User is not authenticated");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  let chatID = userId + "-" + id;

  // const handleSubmit = async () => {
  //   if (userData) {
  //     const chatDocRef = db.collection("Chats").doc(chatID);

  //     try {
  //       const chatDoc = await chatDocRef.get();

  //       if (chatDoc.exists) {
  //         navigate("/chats");
  //       } else {
  //         await chatDocRef.set({
  //           clientID: userId,
  //           clientName: userData.fname,
  //           clientEMail: userData.email,
  //           freelancerID: id,
  //           idd: userId + "-" + id,
  //         });
  //         navigate("/chats");
  //       }
  //     } catch (error) {
  //       console.error("Error submitting user data:", error);
  //       navigate("/chats");
  //     }
  //   }
  // };

  if (loading) {
    return <div class="preloader"></div>;
  }
  return (
    <>
      <GoToTop />
      <div class="body_content">
        <section class="breadcumb-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcumb-style1">
                  <div class="breadcumb-list">
                    <Link to="/">Home</Link>
                    <a href="">Projects</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section pt-0">
          <div class="cta-service freelancer-single-style mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
            <img
              class="left-top-img wow zoomIn"
              src="images/vector-img/left-top.png"
              alt=""
            />
            <img
              class="right-bottom-img wow zoomIn"
              src="images/vector-img/right-botom.png"
              alt=""
            />
            <img
              class="service-v1-vector bounce-y d-none d-xl-block"
              src="images/vector-img/vector-service-v1.png"
              alt=""
            />
            <div class="container">
              <div class="row wow fadeInUp">
                <div class="col-xl-7">
                  <div class="position-relative">
                    <h2>{title}</h2>
                    <div class="list-meta mt15">
                      <p class="mb-0 dark-color fz15 fw500 list-inline-item mb5-sm">
                        <i class="flaticon-place vam fz20 text-thm2 me-2"></i>{" "}
                        {location || "Location not available"}
                      </p>
                      <p class="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                        <i class="flaticon-calendar text-thm2 vam fz20 me-2"></i>{" "}
                        {formattedDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Service Details --> */}
        <section class="pt30">
          <div class="container">
            <div class="row wrap">
              <div class="col-lg-8">
                <div class="column">
                  <div class="row">
                    <div class="col-sm-6 col-xl-4">
                      <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                        <div class="icon flex-shrink-0">
                          <span class="flaticon-dollar"></span>
                        </div>
                        <div class="details">
                          <h5 class="title">Project type</h5>
                          <p class="mb-0 text">{work_type}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4">
                      <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                        <div class="icon flex-shrink-0">
                          <span class="flaticon-fifteen"></span>
                        </div>
                        <div class="details">
                          <h5 class="title">Project Duration</h5>
                          <p class="mb-0 text">{work_duration}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4">
                      <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                        <div class="icon flex-shrink-0">
                          <span class="flaticon-like-1"></span>
                        </div>
                        <div class="details">
                          <h5 class="title">Project Level</h5>
                          <p class="mb-0 text">{work_level}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="service-about">
                    <h4>Description</h4>

                    <p
                      class="text mb30"
                      dangerouslySetInnerHTML={{
                        __html: pDesc,
                      }}
                    ></p>
                    <hr class="opacity-100 mb60 mt60" />
                    <h4 class="mb30">Attachments</h4>
                    <div class="row">
                      <div class="col-6 col-lg-3">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => window.open(fileURL, "_blank")}
                          class="project-attach"
                        >
                          <h6 class="title">Project Brief</h6>
                          <p>PDF</p>
                          <span class="icon flaticon-page"></span>
                        </div>
                      </div>
                    </div>
                    <hr class="opacity-100 mb60 mt30" />
                    <h4 class="mb30">Skills Required</h4>
                    <div class="mb60">
                      {skills
                        ? skills.split(",").map((skill, skillIndex) => (
                            <a
                              class="tag list-inline-item mb-2 mb-xl-0"
                              key={skillIndex}
                            >
                              {skill.trim()}
                              {""}
                              {/* Trim any leading or trailing spaces */}
                            </a>
                          ))
                        : "-"}
                    </div>

                    <div class="bsp_reveiw_wrt mt25">
                      <h4>Send Your Proposal</h4>
                      <form class="comments_form mt30 mb30-md">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="mb20">
                              <label class="fw500 ff-heading dark-color mb-2">
                                Your hourly price
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="$99"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb20">
                              <label class="fw500 ff-heading dark-color mb-2">
                                Estimated Hours
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="4"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="mb-4">
                              <label class="fw500 fz16 ff-heading dark-color mb-2">
                                Cover Letter
                              </label>
                              <textarea
                                class="pt15"
                                rows="6"
                                placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                              ></textarea>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="d-grid">
                              <a href="" class="ud-btn btn-thm">
                                Submit a Proposal
                                <i class="fal fa-arrow-right-long"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="column">
                  <div class="blog-sidebar ms-lg-auto">
                    <div class="price-widget pt25 bdrs8">
                      <h3 class="widget-title">
                        ₹{minCost} - ₹{maxCost}
                      </h3>
                      <p class="text fz14">{work_type} Rate</p>
                      <div class="d-grid">
                        <a href="page-contact.html" class="ud-btn btn-thm">
                          Submit a Proposal
                          <i class="fal fa-arrow-right-long"></i>
                        </a>
                      </div>
                    </div>
                    <div class="freelancer-style1 service-single mb-0 bdrs8">
                      <h4>About Buyer</h4>
                      <div class="wrapper d-flex align-items-center mt20">
                        <div class="thumb position-relative mb25">
                          <img
                            class="rounded-circle mx-auto"
                            src="images/team/client-1.png"
                            alt=""
                          />
                        </div>
                        <div class="ml20">
                          <h5 class="title mb-1">Dropbox</h5>
                          <p class="mb-0">Digital Marketing</p>
                          <div class="review">
                            <p>
                              <i class="fas fa-star fz10 review-color pr10"></i>
                              <span class="dark-color">4.9</span> (595 reviews)
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr class="opacity-100" />
                      <div class="details">
                        <div class="fl-meta d-flex align-items-center justify-content-between">
                          <a class="meta fw500 text-start">
                            Location
                            <br />
                            <span class="fz14 fw400">London</span>
                          </a>
                          <a class="meta fw500 text-start">
                            Employees
                            <br />
                            <span class="fz14 fw400">11-20</span>
                          </a>
                          <a class="meta fw500 text-start">
                            Departments
                            <br />
                            <span class="fz14 fw400">Designer</span>
                          </a>
                        </div>
                      </div>
                      <div class="d-grid mt30">
                        <a
                          href="page-contact.html"
                          class="ud-btn btn-thm-border"
                        >
                          Contact Buyer<i class="fal fa-arrow-right-long"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ProjectDetail;
