import React from "react";
import { Link } from "react-router-dom";
function Sidebar() {
  return (
    <>
      {" "}
      <div class="dashboard__sidebar d-none d-lg-block">
        <div class="dashboard_sidebar_list">
          <p class="fz15 fw400 ff-heading pl30">Dashboard</p>
          <div class="sidebar_list_item">
            <Link to="/admin_dashboard" class="items-center">
              <i class="flaticon-home mr15"></i>Dashboard
            </Link>
          </div>
          <div class="sidebar_list_item">
            <a class="items-center">
              <i class="flaticon-document mr15"></i>My Proposals
            </a>
          </div>
          {/* <div class="sidebar_list_item">
            <a href="page-dashboard-save.html" class="items-center">
              <i class="flaticon-like mr15"></i>Saved
            </a>
          </div> */}
          {/* <div class="sidebar_list_item ">
            <a href="page-dashboard-message.html" class="items-center">
              <i class="flaticon-chat mr15"></i>Message
            </a>
          </div> */}
          <div class="sidebar_list_item ">
            <a class="items-center">
              <i class="flaticon-review-1 mr15"></i>Reviews
            </a>
          </div>
          <div class="sidebar_list_item">
            <a class="items-center">
              <i class="flaticon-receipt mr15"></i>Invoice
            </a>
          </div>
          <div class="sidebar_list_item">
            <a class="items-center">
              <i class="flaticon-dollar mr15"></i>Payouts
            </a>
          </div>
          {/* <div class="sidebar_list_item">
            <a href="page-dashboard-statement.html" class="items-center">
              <i class="flaticon-web mr15"></i>Statements
            </a>
          </div> */}
          <p class="fz15 fw400 ff-heading pl30 mt30">Organize and Manage</p>
          <div class="sidebar_list_item ">
            <Link to="/manage_services" class="items-center">
              <i class="flaticon-presentation mr15"></i>Manage Services
            </Link>
          </div>
          <div class="sidebar_list_item ">
            <a class="items-center">
              <i class="flaticon-briefcase mr15"></i>Manage Jobs
            </a>
          </div>
          <div class="sidebar_list_item ">
            <a class="items-center">
              <i class="flaticon-content mr15"></i>Manage Project
            </a>
          </div>
          <p class="fz15 fw400 ff-heading pl30 mt30">Account</p>
          <div class="sidebar_list_item ">
            <a class="items-center">
              <i class="flaticon-photo mr15"></i>My Profile
            </a>
          </div>
          <div class="sidebar_list_item ">
            <a class="items-center">
              <i class="flaticon-logout mr15"></i>Logout
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
